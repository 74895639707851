<template>
  <el-row class="tac">
    <el-menu
      default-active="2"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      :router="true"
    >
      <el-submenu v-for="item in list" :key="item.index" :index="item.index">
        <template slot="title">
          <i :class="item.icon"></i>
          <span>{{ item.text }}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item
            v-for="ele in item.datas"
            :index="ele.index"
            :key="ele.index"
          >
            <i :class="ele.icon"></i> <span>{{ ele.text }}</span></el-menu-item
          >
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </el-row>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          index: "1",
          text: "首页",
          icon: "el-icon-s-home",
          datas: [{ index: "/system/Home", text: "关于我们" }],
        },
        {
          index: "2",
          text: "患者管理",
          icon: "el-icon-s-custom",
          datas: [
            {
              index: "/System/AddPatient",
              text: "添加患者",
              icon: "el-icon-circle-plus",
            },
            {
              index: "/System/Search",
              text: "查询患者",
              icon: "el-icon-search",
            },
            {
              index: "/system/LookRport",
              text: "查看评估报告",
              icon: "el-icon-notebook-2",
            },
            {
              index: "/system/ReportALL",
              text: "治疗与训练报告",
              icon: "el-icon-notebook-2",
            },
            {
              index: "/system/ReportList",
              text: "患者列表",
              icon: "el-icon-user",
            },
          ],
        },
        {
          index: "3",
          text: "诊疗方案管理",
          icon: "el-icon-menu",
          datas: [
            {
              index: "/system/VRPlan",
              text: "VR心理健康系统",
              icon: "el-icon-monitor",
            },
            {
              index: "/system/Beam",
              text: "自然光照治疗系统",
              icon: "el-icon-sunny",
            },
            {
              index: "/system/CAVE",
              text: "CAVE运动训练系统",
              icon: "el-icon-basketball",
            },
          ],
        },
        {
          index: "4",
          text: "智能评估",
          icon: "el-icon-odometer",
          datas: [
            {
              index: "/System/AssessmentHome",
              text: "答题评估",
              icon: "el-icon-edit",
            },
            {
              index: "/System/QRCode",
              text: "二维码评估",
              icon: "el-icon-full-screen",
            },
          ],
        },
      ],
    };
  },
  methods: {
    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},
  },
};
</script>

<style lang='scss' scoped>
#sidebarBox {
  width: 200px;
  height: 100vh;
}
.tac {
  width: 200px;
}
#sidebarBox > .el-menu {
  border-right: none;
}
.el-submenu__title {
  /* width: 150px; */
  display: flex;
  flex: 1;
  align-items: center;
}
</style>
