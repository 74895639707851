<template>
  <el-container id="SystemBox">
    <el-header
      >智能心理健康云平台
      <!-- 头部渲染信息  -->
      <div>
        <div>
          <el-avatar icon="el-icon-user-solid"></el-avatar>
        </div>
        <el-menu
          class="el-menu-demo"
          mode="horizontal"
          background-color="#90C4F7"
          active-text-color="#409EFF"
          text-color="#fff"
        >
          <el-submenu index="2">
            <template slot="title" class="userBox">{{ name }} </template>
            <el-menu-item index="2-1" align="center" @click="goToUpdate"
              >修改密码</el-menu-item
            >
            <el-menu-item index="2-2" align="center" @click="logout"
              >退出登录</el-menu-item
            >
          </el-submenu>
        </el-menu>
      </div>
    </el-header>
    <el-container>
      <!-- 侧边栏导航  平台管理员管理界面-->
      <AdminSidebar></AdminSidebar>
      <!-- 主要内容 -->
      <el-main id="main">
        <router-view></router-view>
      </el-main>
    </el-container>
    <el-footer>本产品版权归四川书云虚实科技有限公司所有</el-footer>
  </el-container>
</template>

<script>
// import axios from "../axios";
import AdminSidebar from "../views/sidebar/AdminSidebar.vue";
export default {
  components: {
    AdminSidebar,
  },
  data() {
    return {
      // 左侧菜单数据
      menulist: [],

      name: "",
      // 是否折叠
      isCollapse: false,
      // 被激活的链接地址
      activePath: "",
      defaultURL:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
    };
  },
  mounted() {
    this.login();
  },
  methods: {
    //  获取用户的token 中保存的username 并渲染到头部区域 用户：的位置
    login() {
      const userData = localStorage.getItem("userData");
      this.name = JSON.parse(userData);
    },
    //  退出登录 清除token
    logout() {
      window.sessionStorage.clear();
      this.$router.push("/login");
      localStorage.clear();
    },
    // 跳转修改密码
    goToUpdate() {
      window.sessionStorage.clear();
      this.$router.replace("/Update");
      localStorage.clear();
    },
    // 点击按钮，切换菜单的折叠与展开
    toggleCollapse() {
      this.isCollapse = !this.isCollapse;
    },
  },
};
</script>

<style lang='scss' scoped>
// @import "../../css/commonStyle.css";
body {
  margin: 0;
}
#SystemBox {
  height: 100vh;
}
.el-header {
  background-color: rgb(144, 196, 247);
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  color: #fff;
  // color:#96c4f1;
  font-size: 20px;
  > div {
    display: flex;
    align-items: center;
    span {
      margin-left: 15px;
    }
  }
}
.el-footer {
  background-color: rgb(144, 196, 247);

  align-items: center;
  color: #fff;
  font-size: 20px;

  justify-content: space-between;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
}
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
#main {
  text-align: left;
}
#searchBox {
  display: flex;
  width: 200px;
  margin: 10px;
}
#addBox {
  width: 300px;
}
.el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 59.8px;
  line-height: 60px;
  border-bottom: 2px solid transparent;

  color: #909399;
}
</style>
